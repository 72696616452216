import React from 'react';
import { Link } from 'react-router-dom';
import '../css/Home.css';
import MainIcon from '../icons/AIBrainGames.png';
import {PAGES, trackPage, trackEvent} from "../utils/TrackingHelper";
import {Games} from "../components/shared/Constants";

function Home() {
  trackPage(PAGES.HOME);
  return (
      <div className="home-content">
        <div className="home-text">
          <img src={MainIcon} alt="AI Brain Games" className="home-main-icon"/>
          <p className="home-description">Puzzle games created with the power of AI</p>
        </div>
        <div className="home-games">
          <HomeGameSection gameType={Games.CROSSWORD} />
          <HomeGameSection gameType={Games.CHAIN} />
          <HomeGameSection gameType={Games.CLUELESS} />
          <HomeGameSection gameType={Games.WATERFALL} />
        </div>
      </div>
  );
}

function HomeGameSection({gameType}) {
  return (
      <Link to={gameType.url} className="home-tile-button" onClick={() => trackEvent(gameType.trackingId, gameType.trackingHomeClick)}>
        <div className={`home-tile ${gameType.cssId}`}>
          <div className={`home-tile-header ${gameType.cssId}`}>
            <img src={gameType.icon} alt={gameType.title} className="home-tile-icon" />
          </div>
          <div className="home-tile-text-container">
            <h2 className="home-tile-title">{gameType.title}</h2>
            <p className="home-tile-description">{gameType.description}</p>
          </div>
        </div>
      </Link>
  );
}

export default Home;